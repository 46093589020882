<template>
  <div class="body-box">
    <div class="sign-box">
      <img src="@/assets/images/eleSeal/overSign.png" alt="">
      <div class="headline">成功完成签署</div>
      <div class="title-detail">您已成功完成文件签署，</div>
      <div class="title-detail">请点击页面右上角关闭按钮关闭当前页面！</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.body-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -100px;
  .sign-box {
    height: 196px;
    width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 128px;
      height: 122px;
    }
    .headline {
      height: 24px;
      font-size: 16px;
      font-weight: 500;
      color: #1A1A1B;
      line-height: 24px;
      text-align: center;
      margin: 2px 0 4px 0;
    }

    .title-detail {
      height: 22px;
      font-size: 14px;
      font-weight: 400;
      color: #646566;
      line-height: 22px;
      text-align: center;
    }
  }
}

</style>